import { useEffect, useState } from 'react'
import logo from './logo.svg';
import './App.css';
import { initializeApp } from 'firebase/app'
import { getFirestore, getDoc, doc } from 'firebase/firestore'
import TelegramLoginButton from 'react-telegram-login'

const firebaseConfig = {
  apiKey: "AIzaSyAsH52ZKgJ-LLEcqyw1gZraKeXR2OTSdOg",
  authDomain: "basecamp-60002.firebaseapp.com",
  projectId: "basecamp-60002",
  storageBucket: "basecamp-60002.appspot.com",
  messagingSenderId: "65287455785",
  appId: "1:65287455785:web:d3466567da3e2c30590838"
}
// Initialize Firebase

const app = initializeApp(firebaseConfig)

const db = getFirestore(app)



function App() {
  const [user, setUser] = useState()

  useEffect(() => {
      const init = async () => {
        const searchParams = new URLSearchParams(document.location.search)
        const code = searchParams.get('verify')
        if(code){
        const retrieveUser = (await getDoc(doc(db, 'verification', code))).data()
        console.log(retrieveUser)
        if(retrieveUser.code === code){
          
        } else {
          console.log("Code invalid.")
        }
      }
      }
      init()
  }, [])

  const handleTelegramResponse = response => {
    console.log(response);
  };

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
      <TelegramLoginButton version={22} dataOnauth={handleTelegramResponse} botName="BaseCampDevBot" />,

    </div>
  );
}

export default App;
